import styles from '@/styles/searchBar.module.scss'
import { Image } from 'antd';
import { useState, useEffect } from "react";
import { useDebounce } from 'react-use'
import React from 'react';
import { useClickOutside, useCallbackState } from '@/utils/useRef'
import { useRef } from 'react'
import getConfig from 'next/config';
import Link from 'next/link'
import systemApi from '@/service/modules/systemApi'
import { useRouter } from 'next/router';


const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

interface props {
  categoryData?: any
}

export default function SearchBar({ categoryData }: props) {
  const history = useRouter()

  useEffect(() => {
    getHotSearch()
  }, [history.pathname])

  const [hotSearchList, setHotSearchList] = useState([])

  const getHotSearch = async () => {
    try {
      let res = await systemApi.getHotSearch()
      if (res.data) {
        const { keywords } = res.data
        setHotSearchList(keywords)
      }
    } catch (error) {
      console.error(error)
    }
  }


  // categories数据
  const categoriesItems = categoryData.map((item: any) =>
    <div className={styles['categories-list_item']} key={item.categoryID} onClick={() => { setShowCategoriesList(false); setcategories(item.name) }}>
      {item.name}
    </div>
  )
  // 热门搜索数据
  const hotItems = hotSearchList.map(item =>
    <div className={styles['hot-searches_content_item']} key={item} onClick={() => { setInputValue(item, onSearch); }}>
      {item}
    </div>
  )

  const [selectIndex, setSelectIndex] = useState(-1)
  const [showSuggest, setShowSuggest] = useState(false);
  const [showCategoriesList, setShowCategoriesList] = useState(false);
  const [categories, setcategories] = useState('All Categories');
  const [showType, setShowType] = useState('');
  const [inputValue, setInputValue] = useCallbackState('');
  const [productsList, setProductsList]: any[] = useState([])
  const [suggestionsList, setSuggestionsList]: any[] = useState([])

  const ref = useRef(null)
  useClickOutside(ref, () => {
    if (showCategoriesList) {
      setShowCategoriesList(false)
    }
  })

  const searchRef = useRef(null)
  useClickOutside(searchRef, () => {
    if (showSuggest) {
      setShowSuggest(false)
    }
  })

  // 获取推荐数据
  const getDealProduct = async () => {
    try {
      const response = await fetch(`${apiDomain}/api/getFullSearch?keyword=${inputValue}`, {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Accept': '*/*'
        },
        mode: 'cors'
      })

      if (!response.ok) {
        throw new Error('请求出错：' + response.statusText);
      }

      const dealProduct: any = await response.json()
      if (dealProduct.code === 200) {
        const { products, suggestions } = dealProduct.data
        setProductsList(products)
        setSuggestionsList(suggestions)
        setShowType('suggestions')
      } else {
        setProductsList([])
        setSuggestionsList([])
      }


    } catch (error) {
      console.log(error)
    }
  }

  const keyDown = (e: any) => {
    const length = productsList.slice(0, 5).length + suggestionsList.slice(0, 5).length - 1
    switch (e.keyCode) {
      // 回车
      case 13:
        if (selectIndex == -1) {
          onSearch(inputValue)
        } else {
          const concatArr = [...suggestionsList.slice(0, 5), ...productsList.slice(0, 5)]
          const selectItem = concatArr[selectIndex]
          if (selectItem.productUrl) {
            window.location.href = selectItem.productUrl
          } else {
            setInputValue(selectItem, onSearch)
          }
          // onSearch(inputValue)
        }
        break;
      // 上
      case 38:
        if (selectIndex > 0) {
          setSelectIndex(selectIndex - 1)
        } else if (selectIndex == 0) {
          setSelectIndex(length)
        }
        break;
      // 下
      case 40:
        if (selectIndex < length) {
          setSelectIndex(selectIndex + 1)
        } else if (selectIndex == length) {
          setSelectIndex(0)
        }
        break;

    }
  }

  useDebounce(
    () => {
      if (inputValue && inputValue.length >= 3) {
        getDealProduct()
      } else {
        setShowType('hot')
      }
    },
    500,
    [inputValue]
  );

  // 搜索逻辑
  function onSearch(value: string) {
    let url: string = ''
    if (!value) {
      url = "/hottest-deals"
    } else {
      let keyword = value.replace(/ /g, "+")
      if (categories == 'All Categories') {
        url = `/search/${keyword.replace(/\s/g, '+')}`
      } else {
        url = `/search/${keyword.replace(/\s/g, '+')}/category/${categories}/`
      }
    }
    window.location.href = url
  }

  // 获取search页面的参数，赋值给输入框
  const router = useRouter();
  useEffect(() => {
    if (router.asPath.includes('/search/')) {
      const { slug } = router.query
      if (!slug || slug.length <= 0) return

      const path = slug[0]
      const regex = /([^?]+)/;

      // 使用正则表达式匹配并提取路径部分
      var match = path.match(regex)
      // 获取匹配到的结果
      let categoryPath = match ? match[1] : slug[0];
      
      setInputValue(String(categoryPath).replace(/\+/g, " "))
    }
  }, [])

  return (
    <div className={styles['search-bar']} ref={searchRef}>
      <div className={styles['search-bar-box']}>
        <div className={styles['search-input-box']}>
          <input
            id="key"
            type="text"
            value={inputValue}
            placeholder="shop 10000+ products online"
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() => { setShowSuggest(true) }}
            onKeyDown={keyDown}
            className={styles['search-input']}
          />
        </div>
        <div ref={ref} className={styles['search-categories-box']} style={{ display: 'none' }}>
          <span onClick={() => { setShowCategoriesList(!showCategoriesList) }}>{categories}</span>
          <div className={`${styles['categories-list']} ${showCategoriesList && styles['on']}`}>
            {categoriesItems}
          </div>
        </div>
        <div id="search_btn" className={styles['search-btn-box']} onClick={() => { onSearch(inputValue) }}>
          <i className={`iconfont csicon-search ${styles['search-icon']}`}></i>
        </div>

      </div>
      {/* 热门搜索 */}
      <div className={styles['hot-searches']} style={{ 'display': (showSuggest && showType == 'hot') ? 'block' : 'none' }}>
        <h2 className={styles['hot-searches_title']}>Hot Searches</h2>
        <div className={styles['hot-searches_content']}>
          {hotItems}
        </div>
      </div>

      {/* 搜索建议 */}
      {
        (suggestionsList.length > 0 || productsList.length > 0)
        &&
        <div className={styles['suggestions-searches']} style={{ 'display': (showSuggest && showType == 'suggestions') ? 'block' : 'none' }}>
          {
            suggestionsList.length > 0
            &&
            <div className={styles['suggestions']}>
              <div className={styles['suggestions_title']}>Search Suggestions</div>
              <div className={styles['suggestions_content']}>
                {
                  suggestionsList.slice(0, 5).map((item: any, i: number) =>
                    <Link
                      href={encodeURI(`/search/${item.replace(/\s/g, '+')}`)}
                      legacyBehavior
                      key={i}
                    >
                      <a
                        key={i}
                        className={`${styles['suggestions_content_item']} ${i === selectIndex && styles['active-index']}`}
                        rel="noopener noreferrer"
                      >
                        {item}
                      </a>
                    </Link>
                  )
                }
              </div>
            </div>
          }

          {
            productsList.length > 0
            &&
            <div className={styles['product']}>
              <div className={styles['product_title']}>Products</div>
              <div className={styles['product_content']}>

                {
                  productsList.slice(0, 5).map((item: any, i: number) =>
                    <Link
                      href={item.productUrl}
                      legacyBehavior
                      key={i}
                    >
                      <a
                        className={`${styles['product_content_item']} ${suggestionsList.slice(0, 5).length + i === selectIndex && styles['active-index']}`}
                        rel="noopener noreferrer"
                      >
                        <div className={styles['product_content_item_img']}>
                          <Image
                            alt={item.productName}
                            width={48}
                            height={48}
                            preview={false}
                            src={item.imageUrl} />
                        </div>
                        <div className={styles['product_content_item_desc']}>
                          <h3>{item.productName}</h3>
                          <p>{item.productDescription}</p>
                        </div>
                        <div className={styles['product_content_item_price']}>
                          ${item.price}
                        </div>
                      </a>
                    </Link>

                  )
                }
              </div>
            </div>
          }
        </div>
      }
    </div >
  )
}
